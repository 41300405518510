<template>
  <div>
    <z-mantenedor
      v-model="items"
      :campo-subtitulo-dialog="(v) => formatAccion(v.nombre)"
      :campo-titulo-dialog="(v) => ` ${v.nombre}`"
      :headers="headers"
      :query="query"
      :url="tramiteUrl"
      :search-url="tramiteUrl + '/search?with_trashed=true'"
      search-method="post"
      :on-after-search="afterSearch"
      :search-inicial="$route.query.buscar === 'true'"
      disable-edit
      disable-delete
      texto-filtrar="Filtrar"
    >
      <template #buscador="{ loading, search }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col v-if="puedeSeleccionarInstitucion" cols="12" sm="6" md="5">
              <z-input
                v-model="query.filter.institucion_id"
                :items="payloadItemsInstituciones"
                clearable
                label="Institución"
                tipo="autocomplete"
              />
            </v-col>
            <v-col cols="7" sm="3">
              <z-input
                v-model="query.scope.estado"
                tipo="select"
                :items="[
                  { value: 'por-importar', text: 'Por importar' },
                  { value: 'importando', text: 'Importando' },
                  { value: 'importada', text: 'Importada' },
                  { value: 'error', text: 'Error' },
                  { value: 'eliminada', text: 'Eliminada' },
                ]"
                label="Estado"
                clearable
              />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #item.fecha="{ value }">
        {{
          $dayjs(value).format(
            $dayjs(value).isBefore($dayjs().subtract(1, 'day')) ? 'D-MMM HH:mm:ss' : 'HH:mm:ss'
          )
        }}
        <div class="text--secondary text-caption">({{ $dayjs(value).fromNow() }})</div>
      </template>

      <template #item.nombre="{ value, item }">
        {{ value }}
        <div class="text--secondary text-caption">
          {{ item.user_nombre }}
        </div>
      </template>

      <template #item.estado="{ item }">
        <chip-nomina :title="item.error" :nomina="item" small />
      </template>

      <template #item.cantidad="{ value, item }">
        <v-chip
          v-if="value > 0"
          small
          :to="`/cuentas?nomina_id=${item.id}&institucion_id=${item.institucion_id}&tramite=${slugTramite}&buscar=true`"
        >
          {{ $formato.numero(value || 0) }}
        </v-chip>
      </template>

      <template #view="{ item }">
        <nominas-view :item="item" />
      </template>
    </z-mantenedor>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZMantenedor from '@/components/ZMantenedor'
import NominasView from '@/admin/views/Nomina/NominasView'
import ZInput from '@/components/Input/ZInput'
import ChipNomina from '@/admin/components/ChipNomina'

export default {
  components: { ChipNomina, ZInput, ZMantenedor, ZBtn, NominasView },
  data() {
    return {
      items: [],
      itemActivo: {},
      mostrarTodo: false,
      historialVisible: false,
      slugTramite: this.$route.query.tramite ?? '',
      headerOptions: {
        institucion_id: {
          visible: !this.$route.query.institucion_id,
        },
      },
      query: {
        filter: {
          institucion_id: parseInt(this.$route.query.institucion_id),
        },
        scope: {
          estado: this.$route.query.estado,
        },
        sort: {
          0: { field: 'created_at', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    tramiteUrl() {
      return '/bum/bum-nominas'
    },
    headers() {
      return [
        {
          text: 'Creación',
          value: 'created_at',
          formatter: (v) => this.$formato.fechaFromNow(v, 1, 'day', 'L H:mm'),
        },
        {
          text: 'Institución',
          value: 'institucion_id',
          class: 'text-no-wrap',
          visible: this.puedeSeleccionarInstitucion && this.headerOptions.institucion_id.visible,
          formatter: (v, item) => this.payloadListaInstituciones[item.institucion_id],
        },
        {
          text: 'Nómina',
          value: 'nombre',
        },
        {
          text: 'Estado',
          value: 'estado',
        },
      ]
    },

    puedeSeleccionarInstitucion() {
      return this.$auth.can(['operador', 'mesa-de-ayuda'])
    },
  },
  methods: {
    afterSearch() {
      this.headerOptions.institucion_id.visible = !this.query.filter.institucion_id
    },
    formatAccion(nombre) {
      this.errorVisible = false
      return (
        {
          created: 'creado',
          updated: 'editado',
          deleted: 'eliminado',
        }[nombre] || ''
      )
    },
    descargarArchivo(item) {
      const urlDescarga = `${this.tramiteUrl}/${item.id}/archivo`
      this.$axios
        .get(urlDescarga, { responseType: 'blob' })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = item.archivo
          link.click()
          this.$emit('notificar', 'Descarga exitosa.')
        })
        .catch((error) => {
          console.log(error)
          this.$emit('notificar:error', 'El archivo seleccionado no se encuentra disponible.')
        })
    },
  },
}
</script>
