<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th>Fecha de creación</th>
          <td>{{ $dayjs(item.created_at).format('LLLL:ss') }}</td>
        </tr>
        <tr v-if="item.fecha_inicio_importacion">
          <th>Fecha de inicio de la importación</th>
          <td>{{ $dayjs(item.fecha_inicio_importacion).format('LLLL:ss') }}</td>
        </tr>
        <tr v-if="item.fecha_importacion">
          <th>Fecha de importación</th>
          <td>{{ $dayjs(item.fecha_importacion).format('LLLL:ss') }}</td>
        </tr>
        <tr>
          <th>Usuario</th>
          <td>{{ item.nombre_usuario }}</td>
        </tr>
        <tr>
          <th>Nombre</th>
          <td>{{ item.nombre }}</td>
        </tr>
        <tr>
          <th>Archivo</th>
          <td>{{ item.archivo }}</td>
        </tr>
        <tr v-if="item.hoja">
          <th>Hoja</th>
          <td>{{ item.hoja }}</td>
        </tr>
        <tr v-if="item.cantidad">
          <th>Cuentas importadas</th>
          <td>{{ $formato.numero(item.cantidad) }}</td>
        </tr>
        <tr>
          <th>Estado</th>
          <td>
            <chip-nomina :nomina="item" small />
          </td>
        </tr>
        <tr v-if="item.error">
          <th>Error</th>
          <td>{{ item.error }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <div v-if="!!item.errores" class="text-h6 mt-8">Detalle de errores</div>
    <cargar-nomina-errores v-if="item.errores" :errores="item.errores" :inicio="1" />
  </div>
</template>

<script>
import ChipNomina from '@/admin/components/ChipNomina'
import CargarNominaErrores from '@/admin/views/Nomina/CargarNominaErrores'
export default {
  components: { CargarNominaErrores, ChipNomina },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
