<template>
  <v-chip
    :color="color(nomina.estado)"
    :outlined="outlined(nomina.estado)"
    dark
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $payload.estadoNomina(nomina.estado) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    nomina: {
      type: Object,
      required: true,
    },
  },
  methods: {
    color(estado) {
      return (
        {
          1: 'warning', // CREADA
          9: 'error', // ERROR_CREACION
          10: 'warning', // POR_IMPORTAR
          12: 'warning', // IMPORTANDO
          15: 'success', // IMPORTADA
          19: 'error', // ERROR_IMPORTACION
          95: 'grey', // ELIMINADA
        }[estado] ?? 'error'
      )
    },
    outlined(estado) {
      // Los estados "no finales" serán outlined
      return estado !== 9 && estado !== 19 && estado !== 15 && estado !== 95
    },
  },
}
</script>
