<template>
  <div>
    <v-card
      max-width="600"
      class="mx-auto mt-sm-4 mb-sm-10 mb-8"
      :elevation="$vuetify.breakpoint.xs ? 0 : 2"
    >
      <v-form role="search" aria-label="Buscador" @submit.prevent="buscar">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-text-field
              v-model.trim="form.search"
              class="mx-4 input-buscador"
              outlined
              persistent-hint
              aria-label="Buscar"
              type="search"
              name="search"
              spellcheck="false"
              inputmode="search"
              data-testid="buscar"
            >
              <template slot="append-outer">
                <v-btn color="primary" type="submit" class="mt-n2">
                  <v-icon class="hidden-sm-and-up"> mdi-magnify </v-icon>
                  <span class="hidden-xs-only">Buscar</span>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card
      v-if="resultados"
      v-bind="$attrs"
      outlined
      class="mx-auto mt-sm-4 mb-sm-10 mb-8"
      :max-width="600"
    >
      <v-card-title class="v-card__title fondo3">
        <span class="text-h5 text-slab"> Patente {{ patente }} </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-expansion-panels class="mt-5" focusable>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1"> Vehículo </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="mb-4 mt-4 labels">
                <tbody>
                  <tr v-for="(valor, titulo) in vehiculo" :key="valor">
                    <td>{{ campoALabelModelVehiculo(titulo) }}</td>
                    <td>{{ valorALabelModelVehiculo(valor, titulo) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1"> Permisos </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="mb-4 mt-4 labels">
                <tbody>
                  <tr v-for="(permiso, i) in permisos" :key="i">
                    <td style="border-bottom: 1px solid grey">
                      <v-simple-table class="labels">
                        <tbody>
                          <tr v-for="(valor, titulo) in permiso" :key="titulo">
                            <td>{{ campoALabelModelPermiso(titulo) }}</td>
                            <td>{{ valorALabelModelPermiso(valor, titulo) }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-alert v-if="error" class="mx-auto" width="500" type="info" outlined>
      <p class="text-h6">
        {{ error }}
      </p>
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      visible: true,
      itemDetalle: {},
      vehiculo: [],
      form: {
        search: '',
        institucion_id: '',
      },
      resultados: false,
      permisos: [],
      error: '',
      patente: '',
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    buscar() {
      const search = this.form.search?.trim().toUpperCase()
      this.vehiculo = []
      this.permisos = []
      this.error = ''

      console.log(search)
      if (!search) {
        this.resultados = false

        alert('Debes ingresar un texto de búsqueda.')

        return
      }
      this.$axios
        .get('bum/vehiculos/show-vehiculo?patente=' + search)
        .then((response) => {
          if (typeof response.data === 'object') {
            // this.cuentas = response.data.data || response.data || []
            this.vehiculo = response.data.data || response.data || []
            this.patente = this.$formato.placaPatente(this.vehiculo.patente)
            this.form.search = this.patente
            this.permisos = this.vehiculo.pagos
            delete this.vehiculo.pagos
            delete this.vehiculo.permisos
            delete this.vehiculo.propietarios
            this.resultados = true
          } else {
            this.resultados = false
          }
        })
        .catch((e) => {
          this.error = 'No se encontraron resultados.'

          this.resultados = false
        })
    },
    campoALabelModelPermiso(campo) {
      let label = campo.replaceAll('_', ' ')

      label = label[0].toUpperCase() + label.slice(1)

      if (label === 'Ano') label = 'Año'
      if(label === 'Institucion id') label = 'Institución'

      return label
    },
    campoALabelModelVehiculo(campo) {
      let label = campo.replaceAll('_', ' ')

      label = label[0].toUpperCase() + label.slice(1)

      if (label === 'Ano fabricacion') label = 'Año de fabricación'
      if (label === 'Codigo sii') label = 'Código SII'
      if (label === 'Tipo vehiculo') label = 'Tipo de vehículo'
      if (label === 'Digito verificador') label = 'Dígito verificador'

      return label
    },
    valorALabelModelVehiculo(valor, titulo) {
      if (titulo === 'patente') {
        return this.$formato.placaPatente(valor)
      }

      return valor
    },
    valorTotalPermiso(permiso) {
      var valor = 0
      if (Math.round(0 + permiso.monto_cuota_1) > 0) {
        return valor + Math.round(0 + permiso.monto_cuota_1)
      }
      if (Math.round(0 + permiso.monto_cuota_2) > 0) {
        return valor + Math.round(0 + permiso.monto_cuota_2)
      }

      return this.$formato.moneda(valor)
    },
    valorALabelModelPermiso(valor, titulo) {
      if (titulo === 'monto_cuota_1' || titulo === 'monto_cuota_2') {
        return this.$formato.moneda(valor)
      }

      if (titulo === 'tipo_pago') {
        if (valor * 1 === 1) return 'Primera cuota'
        if (valor * 1 === 2) return 'Segunda cuota'
        if (valor === 'T') return 'Total pagado'
      }

      if(titulo === 'institucion_id'){
        console.log(this.$payloadStatic.municipalidad(valor).nombre)
        return this.$payloadStatic.municipalidad(valor).nombre
      }

      return valor
    },
  },
}
</script>
